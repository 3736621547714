<template>
  <div class="news">
    <div class="bgone">
      <div class="bgone_tit">
        <div>
          <div><h2>企业动态</h2></div>
          <div>
            <p>公司动态、行业资讯等精彩内容</p>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #f6f6f6; height: 60px">
      <div
        class="contWidth1440 marginAuto align-items"
        style="padding-top: 20px"
      >
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <img
              style="width: 10px; margin-bottom: 2px; margin-right: 5px"
              src="/mp/VirHome/homes.png"
              alt=""
            />
            <span class="span" @click="home">首页</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <span class="span" @click="back">企业动态</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <span class="span">正文</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div style="padding-bottom: 60px; padding-top: 30px; width: 100%">
      <div class="container">
        <div class="inner_breadcrumb"></div>

        <div class="inner_content" style="padding: 80px 0 100px">
          <div class="inner_title_nav">
            <div class="inner_title">{{ info.title }}</div>
            <div class="inner_creator">
              <div class="inner_time">{{ info.updateTime }}</div>
              <div class="inner_source">来源：{{ info.docSource }}</div>
            </div>
          </div>
          <div class="inner_area" v-html="info.docContent"></div>
        </div>
      </div>
    </div>
    <HomeFooterBanner></HomeFooterBanner>
  </div>
</template>

<script>
import BannerImg from "@/components/BannerImg";
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";
import { gethelpcenter } from "@/api/HelpCenter";

export default {
  name: "NewsInfo",
  components: {
    BannerImg,
    HomeFooterBanner,
  },
  data() {
    return {
      info: {
        docContent:
          "随着应用用户流量逐渐趋向饱和状态，移动应用产品变现的商业化地位越来越突出，如何将商业模式和商业资源化为己用，是开发者值得关注且亟待解决的问题。万鲸认为，一个更开放的商业生态将有助于解决开发者的商业化痛点，释放行业创新力。因此，万鲸广告平台深掘行业发展前景，助推开发者们实现优质的商业成长。",
      },
    };
  },
  created() {
    console.log("idididid", this.$route.query);
    this.getInfo();
  },
  methods: {
    home() {
      localStorage.removeItem("activeKey");
      this.$router.push({
        path: "/",
      });
    },
    back() {
      this.$router.back();
    },
    getInfo() {
      gethelpcenter(this.$route.query.id).then((res) => {
        console.log("详情", res);
        this.info = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bgone {
  background: url(../../assets/home/dd.png) no-repeat;
  height: 450px;
  width: 100%;
  background-size: 100% 100%;
  &_tit {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.inner_title_nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  .inner_title {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 700;
    color: #333;
  }

  .inner_line {
    width: 100px;
    height: 4px;
    background: #ff6a00;
    margin: 18px 0 17px;
  }

  .inner_creator {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333;
    display: flex;

    .inner_source {
      margin-left: 20px;
    }
  }
}

.inner_area {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333;
  line-height: 36px;
  text-indent: 2em;
}

.ant-breadcrumb {
  color: rgba(0, 0, 0, 0.65) !important;

  .span:hover {
    color: #ff6a00;
    cursor: pointer;
  }
}
</style>
